import React, { useState, useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { graphql, useStaticQuery } from 'gatsby'
import Layout from '../components/Layout'
import HeaderSEO from '../components/partials/HeaderSEO'

import SmallSpacing from '../components/partials/SmallSpacing'
import Spacing from '../components/partials/Spacing'

interface Author {
  name: string
}
interface Summary {
  summary: string
}

interface ProductSheetNodeObject {
  node: ProductSheetNode
}

interface Internal {
  type: string
}

interface ProductSheetNode {
  title: string
  date: string
  slug: string
  description: string
  categoryType: string
  typeColor: string
  pdfType: string
  internal: Internal
}

interface VideoNodeObject {
  node: VideoNode
}

interface VideoNode {
  title: string
  date: string
  slug: string
  videoTypeColor: string
  videoType: string
  videoId: string
  summary: Summary
  internal: Internal
}

interface WebinarNodeObject {
  node: WebinarNode
}
interface WebinarNode {
  title: string
  date: string
  slug: string
  author: Author
  internal: Internal
}

interface CustomResourceNodeObject {
  node: CustomResourceNode
}
interface CustomResourceNode {
  title: string
  date: string
  slug: string
  subtitle: string
  categoryType: string
  internal: Internal
}

interface BlogNodeObject {
  node: BlogNode
}
interface BlogNode {
  title: string
  date: string
  slug: string
  subtitle: string
  description: string
  isArticle: boolean
  author: Author
  internal: Internal
}

interface StateProps {
  resourcesState: ResourcesListState
}
interface ResourcesListState {
  resourcesItems: Array<ResourceItem>
  clickedMoreNTimes: number
  allResources: Array<ResourceItem>
}

interface ResourceItem {
  node:
    | ProductSheetNode
    | VideoNode
    | WebinarNode
    | CustomResourceNode
    | BlogNode
}

const resources: React.FC<StateProps> = () => {
  const data = useStaticQuery(graphql`
    query ResourcesQuery {
      allContentfulProductSheet {
        edges {
          node {
            date
            description
            slug
            title
            categoryType
            typeColor
            pdfType
            internal {
              type
            }
          }
        }
      }
      allContentfulVideo(sort: { fields: date, order: DESC }) {
        edges {
          node {
            date
            title
            slug
            videoTypeColor
            videoType
            videoId
            summary {
              summary
            }
            internal {
              type
            }
          }
        }
      }
      allContentfulWebinar {
        edges {
          node {
            date
            title
            slug
            author {
              name
            }
            internal {
              type
            }
          }
        }
      }
      allContentfulCustomResources {
        edges {
          node {
            categoryType
            slug
            subtitle
            title
            date
            internal {
              type
            }
          }
        }
      }
      allContentfulLensesBlog {
        edges {
          node {
            title
            subtitle
            slug
            isArticle
            date
            description
            author {
              name
            }
            internal {
              type
            }
          }
        }
      }
      allContentfulPages(
        filter: { pageReference: { pageIdentifier: { eq: "Resources" } } }
      ) {
        edges {
          node {
            title {
              title
            }
            keywords {
              keywords
            }
            description {
              description
            }
            oGimage {
              fluid(quality: 100) {
                src
              }
            }
          }
        }
      }
    }
  `)
  // GraphQL DATA
  const pageSEO = data.allContentfulPages.edges[0].node
  const productSheetsList: Array<ProductSheetNodeObject> =
    data.allContentfulProductSheet.edges
  const videos: Array<VideoNodeObject> = data.allContentfulVideo.edges
  const webinars: Array<WebinarNodeObject> = data.allContentfulWebinar.edges
  // Filter blogs for only Articles & get the 5 most recent
  // Blogs are defined as articles in Contentful
  const blogs: Array<BlogNodeObject> = data.allContentfulLensesBlog.edges
    .filter((blog) => {
      return blog.node.isArticle === true
    })
    .slice(0, 5)
  const customResources: Array<CustomResourceNodeObject> =
    data.allContentfulCustomResources.edges
  //

  const [resourcesState, setResourcesState] = useState<ResourcesListState>({
    resourcesItems: [],
    clickedMoreNTimes: 0,
    allResources: [],
  })

  const { resourcesItems, clickedMoreNTimes, allResources } = resourcesState

  useEffect(() => {
    // Merge to all resources
    // Declaring as any to prevent Concat issue
    let tempAllResources: ResourceItem[] = (productSheetsList as Array<any>)
      .concat(videos)
      .concat(webinars)
      .concat(blogs)
      .concat(customResources)

    // Sort resources based on dates (Max to Min)
    sortByDate(tempAllResources)

    let firstNineResourcesItems: ResourceItem[] = tempAllResources.slice(0, 9)

    setResourcesState({
      resourcesItems: firstNineResourcesItems,
      clickedMoreNTimes: 1,
      allResources: tempAllResources,
    })
  }, [])

  // Format used to render the date
  const dateOptions = {
    year: 'numeric',
    month: 'short',
  } as const

  const handleMore = () => {
    let n: number = clickedMoreNTimes
    n++
    // Check if there are items left in the array to show
    if (allResources.length >= 9 * n) {
      setResourcesState({
        ...resourcesState,
        resourcesItems: allResources.slice(0, 9 * n),
        clickedMoreNTimes: n,
      })
      // If not hide "more" button
    } else {
      removeMoreItemsButton()
    }
  }

  const filterCustomResources = (
    resourceCategoryType:
      | 'CustomWebinarsVideos'
      | 'CustomBooksReports'
      | 'CustomTools'
  ) => {
    // Getting customResources from GraphQL
    return customResources.filter(
      (resource) => resource.node.categoryType === resourceCategoryType
    )
  }

  const filterProductSheets = (
    productSheetCategoryType: 'BooksReports' | 'Tools'
  ) => {
    // Getting productSheetsList from GraphQL
    return productSheetsList.filter(
      (productSheet) =>
        productSheet.node.categoryType === productSheetCategoryType
    )
  }

  const removeMoreItemsButton = () => {
    if (document.getElementById('handleMore')) {
      document.getElementById('handleMore').remove()
    }
  }

  // Sort resources based on dates (Max to Min)
  const sortByDate = (array) => {
    return array.sort(function (a, b) {
      var c = +new Date(a.node.date)
      var d = +new Date(b.node.date)
      return d - c
    })
  }

  const handleWebinarsVideos = () => {
    let customResourceList = filterCustomResources('CustomWebinarsVideos')

    let tempResourcesItems = (webinars as any)
      .concat(videos)
      .concat(customResourceList)

    setResourcesState({
      ...resourcesState,
      resourcesItems: sortByDate(tempResourcesItems),
    })

    removeMoreItemsButton()
  }

  const handleBooksReports = () => {
    let customResourceList: Array<CustomResourceNodeObject> =
      filterCustomResources('CustomBooksReports')
    let filteredList: Array<ProductSheetNodeObject> =
      filterProductSheets('BooksReports')

    let tempResourcesItems: Array<
      CustomResourceNodeObject | ProductSheetNodeObject
    > = (
      filteredList as Array<CustomResourceNodeObject | ProductSheetNodeObject>
    ).concat(customResourceList)

    setResourcesState({
      ...resourcesState,
      resourcesItems: sortByDate(tempResourcesItems),
    })
    removeMoreItemsButton()
  }

  const handleTools = () => {
    let customResourceList: Array<CustomResourceNodeObject> =
      filterCustomResources('CustomTools')
    let filteredList: Array<ProductSheetNodeObject> =
      filterProductSheets('Tools')

    let tempResourcesItems: Array<
      CustomResourceNodeObject | ProductSheetNodeObject
    > = (
      filteredList as Array<ProductSheetNodeObject | CustomResourceNodeObject>
    ).concat(customResourceList)

    setResourcesState({
      ...resourcesState,
      resourcesItems: sortByDate(tempResourcesItems),
    })
    removeMoreItemsButton()
  }

  const handleSearch = (e) => {
    let searchArrayThatUpdatesState = []
    const regex = RegExp(e.target.value.toLowerCase())

    allResources.forEach((resource) => {
      if (regex.test(resource.node.title.toLowerCase())) {
        searchArrayThatUpdatesState.push(resource)
      }
    })
    if (searchArrayThatUpdatesState.length > 0) {
      setResourcesState({
        ...resourcesState,
        resourcesItems: searchArrayThatUpdatesState,
      })
      removeMoreItemsButton()
    }
  }

  return (
    <Layout>
      <HeaderSEO pageSEO={pageSEO} />
      <section>
        <div className="container-1 hero-margin">
          <div className="d-flex flex-wrap mt-5">
            <div className="col-12 col-md-8 offset-md-2 text-center pt-0">
              <h1 className="mobile-margin-top">DataOps Resources</h1>
              <h2 className="hero-description-dark">
                Where data engineers come to keep up on all things Apache Kafka,
                event streaming platforms, real-time apps and analytics.
              </h2>
            </div>
          </div>
        </div>
      </section>

      <Spacing />

      <section className="footer-color mobile-margin-top-half">
        <SmallSpacing />
        <div className="container-1">
          <div className="d-flex flex-wrap text-white background-featured-resources-full rounded-1 align-items-center">
            <div className="offset-6 col py-5 px-0">
              <p className="text-uppercase text-white f-16">
                MATURITY & ROI CALCULATOR
              </p>
              <p className="text-white f-28 lh-29">
                <b>Turning Kafka into ca$h</b>
              </p>
              <div className="roll-button pt-3">
                <a
                  className="bg-white link-color button-mobile-font-size"
                  href="/kafka-roi-calculator/"
                >
                  <span data-hover="Try now">Try now</span>
                </a>
              </div>
            </div>
          </div>
          <div className="d-flex flex-wrap mt-4 align-items-center">
            <div className="col-12 col-sm background-featured-resources-half rounded-1 mr-3 mobile-margin-reset">
              <p className="text-uppercase text-white f-16">Tools</p>
              <p className="text-white f-28 lh-29">
                The <b> Managed Kafka</b> <br /> comparison guide
                <br />
                <br />
              </p>
              <div className="roll-button pt-3">
                <a
                  className="bg-white link-color button-mobile-font-size"
                  href="/cloud/managed-kafka-comparison-guide/"
                >
                  <span data-hover="Compare">Compare</span>
                </a>
              </div>
            </div>
            <div className="col-12 col-sm background-featured-resources-half-2 rounded-1 ml-3 mobile-margin-top-half mobile-margin-left-0">
              <p className="text-uppercase text-white f-16">GUIDE</p>
              <p className="text-white f-28 lh-29">
                All you need to know about <br />{' '}
                <b> Kafka monitoring & data observability</b>
              </p>
              <div className="roll-button pt-3">
                <a
                  className="bg-white link-color button-mobile-font-size"
                  href="/guide/everything-need-know-about-kafka-monitoring/"
                >
                  <span data-hover="Read more">Read more</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <SmallSpacing />
      </section>

      <Spacing />
      <section>
        <div className="container-1 mobile-margin-top">
          <div className="d-flex flex-wrap">
            <div className="button-secondary-color col-sm-7 col-md-3 col-lg-4 col-12  mobile-padding-left">
              <i className="fa fa-search border-right pr-1 mr-1"></i>
              <input
                type="text"
                className="border-0 w-75"
                autoComplete="off"
                placeholder="Search"
                onChange={(e) => handleSearch(e)}
              />{' '}
            </div>
            <div className="col mobile-margin-top-half">
              <div className="d-flex flex-wrap justify-content-start justify-content-md-end flex-column flex-md-row">
                <div className="px-4 mobile-padding-reset">
                  <p className="f-14 fw-600 cera-pro">
                    <a
                      className="cursor-pointer"
                      onClick={handleWebinarsVideos}
                    >
                      <span className="resources-category-dot bg-coral mr-2"></span>
                      Webinars & Videos
                    </a>
                  </p>
                </div>
                <div className="px-4 mobile-padding-reset">
                  <p className="f-14 fw-600 cera-pro">
                    <a className="cursor-pointer" onClick={handleBooksReports}>
                      <span className="resources-category-dot bg-lime mr-2"></span>
                      Books & Reports
                    </a>
                  </p>
                </div>
                <div className="px-4 mobile-padding-reset">
                  <p className="f-14 fw-600 cera-pro">
                    <a className="cursor-pointer" onClick={handleTools}>
                      <span className="resources-category-dot bg-blue-2 mr-2"></span>
                      Tools
                    </a>
                  </p>
                </div>
                <div className="pl-4 mobile-padding-reset">
                  <p className="f-14 fw-600 cera-pro">
                    <a
                      className="text-dark"
                      href="/blog/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="resources-category-dot bg-purple mr-2"></span>
                      Articles
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="mt-5 bg-white pb-5">
        <div className="container-1">
          <div className="d-flex flex-wrap">
            <hr className="mt-5" />

            {/* <!-- PRODUCT SHEETS --> */}
            <div className="w-100">
              <div className="d-flex flex-wrap mt-2">
                {(resourcesItems as any).map(({ node }) => {
                  // For Webinars and Videos
                  if (node.videoId) {
                    return (
                      <div
                        className="col-sm-12 col-md-6 col-lg-4 mb-5 mt-3"
                        key={uuidv4()}
                      >
                        <div className="bg-light-gray p-3 rounded-top-1 resources-top-box resources-videos-bg-hover">
                          <a
                            href={`/videos/${node.slug}/`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <p className="f-14 fw-600 cera-pro">
                              <span className="resources-category-dot bg-coral mr-2"></span>
                              Webinars & Videos
                            </p>
                            <h2>{node.title}</h2>
                          </a>
                        </div>
                        <div className="resources-bottom-box p-3">
                          <p>{node.summary.summary.substring(0, 100)}...</p>
                          <a
                            href={`/videos/${node.slug}/`}
                            className="light-blue"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Watch video{' '}
                            <i className="blue-pointer-right-resources"></i>
                          </a>
                        </div>
                      </div>
                    )
                  }
                  // For tools
                  else if (node.categoryType === 'Tools') {
                    return (
                      <div
                        className="col-sm-12 col-md-6 col-lg-4 mb-5 mt-3"
                        key={uuidv4()}
                      >
                        <div className="p-3 rounded-top-1 resources-top-box resources-tools-bg-hover">
                          <a
                            href={`/product-sheets/${node.slug}/`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <p className="f-14 fw-600 cera-pro">
                              <span className="resources-category-dot bg-blue-2 mr-2"></span>
                              Tools
                            </p>
                            <h2>{node.title}</h2>
                          </a>
                        </div>
                        <div className="resources-bottom-box p-3">
                          <p>{node.description.substring(0, 100)}...</p>
                          <a
                            href={`/product-sheets/${node.slug}/`}
                            className="light-blue "
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Download now{' '}
                            <i className="blue-pointer-right-resources"></i>
                          </a>
                        </div>
                      </div>
                    )
                  }
                  // For Books & reports
                  else if (node.categoryType === 'BooksReports') {
                    return (
                      <div
                        className="col-sm-12 col-md-6 col-lg-4 mb-5 mt-3"
                        key={uuidv4()}
                      >
                        <div className="bg-light-gray p-3 rounded-top-1 resources-top-box resources-books-bg-hover">
                          <a
                            href={`/product-sheets/${node.slug}/`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <p className="f-14 fw-600 cera-pro">
                              <span className="resources-category-dot bg-lime mr-2"></span>
                              Books & Reports
                            </p>
                            <h2>{node.title}</h2>
                          </a>
                        </div>
                        <div className="resources-bottom-box p-3">
                          <p>{node.description.substring(0, 100)}...</p>
                          <a
                            href={`/product-sheets/${node.slug}/`}
                            className="light-blue "
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Download now{' '}
                            <i className="blue-pointer-right-resources"></i>
                          </a>
                        </div>
                      </div>
                    )
                  }
                  // Custom Webinars & Videos
                  // They fall under Webinars & Videos :)
                  else if (node.categoryType === 'CustomWebinarsVideos') {
                    return (
                      <div
                        className="col-sm-12 col-md-6 col-lg-4 mb-5 mt-3"
                        key={uuidv4()}
                      >
                        <div className="bg-light-gray p-3 rounded-top-1 resources-top-box resources-videos-bg-hover">
                          <a
                            href={`${node.slug}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <p className="f-14 fw-600 cera-pro">
                              <span className="resources-category-dot bg-coral mr-2"></span>
                              Webinars & Videos
                            </p>
                            <h2>{node.title}</h2>
                          </a>
                        </div>
                        <div className="resources-bottom-box p-3">
                          <p>{node.subtitle.substring(0, 100)}...</p>
                          <a
                            href={`${node.slug}`}
                            className="light-blue"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Download now{' '}
                            <i className="blue-pointer-right-resources"></i>
                          </a>
                        </div>
                      </div>
                    )
                  }
                  // Custom tools under tools
                  else if (node.categoryType === 'CustomTools') {
                    return (
                      <div
                        className="col-sm-12 col-md-6 col-lg-4 mb-5 mt-3"
                        key={uuidv4()}
                      >
                        <div className="bg-light-gray p-3 rounded-top-1 resources-top-box resources-tools-bg-hover">
                          <a
                            href={`${node.slug}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <p className="f-14 fw-600 cera-pro">
                              <span className="resources-category-dot bg-blue-2 mr-2"></span>
                              Tools
                            </p>
                            <h2>{node.title}</h2>
                          </a>
                        </div>
                        <div className="resources-bottom-box p-3">
                          <p>{node.subtitle.substring(0, 100)}...</p>
                          <a
                            href={`${node.slug}`}
                            className="light-blue "
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Download now{' '}
                            <i className="blue-pointer-right-resources"></i>
                          </a>
                        </div>
                      </div>
                    )
                  }
                  // Custom Book Reports under Book and reports
                  else if (node.categoryType === 'CustomBooksReports') {
                    return (
                      <div
                        className="col-sm-12 col-md-6 col-lg-4 mb-5 mt-3"
                        key={uuidv4()}
                      >
                        <div className="bg-light-gray p-3 rounded-top-1 resources-top-box resources-books-bg-hover">
                          <a
                            href={`${node.slug}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <p className="f-14 fw-600 cera-pro">
                              <span className="resources-category-dot bg-lime mr-2"></span>
                              Books & Reports
                            </p>
                            <h2>{node.title}</h2>
                          </a>
                        </div>
                        <div className="resources-bottom-box p-3">
                          <p>{node.subtitle.substring(0, 100)}...</p>
                          <a
                            href={`${node.slug}`}
                            className="light-blue "
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Download now{' '}
                            <i className="blue-pointer-right-resources"></i>
                          </a>
                        </div>
                      </div>
                    )
                  }
                  // Referring to Articles (blogs)
                  else if (node.subtitle) {
                    // Grabbing the date from Contentful Node
                    let tempDate = node.date
                    // Create new Date object from the tempDate
                    let dateToDisplay = new Date(tempDate)
                    // Getting the year from the date - used in the url
                    let year = dateToDisplay.getFullYear()
                    // Getting the month from the date & properly formatting - used in the url
                    let month = dateToDisplay.getMonth()
                    month++
                    let monthString = ('0' + month).slice(-2)
                    return (
                      <div
                        className="col-sm-12 col-md-6 col-lg-4 mb-5 mt-3"
                        key={uuidv4()}
                      >
                        <div className="bg-light-gray p-3 rounded-top-1 resources-top-box resources-blogs-bg-hover">
                          <a
                            href={`/blog/${year}/${monthString}/${node.slug}/`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <p className="f-14 fw-600 cera-pro">
                              <span className="resources-category-dot bg-purple mr-2"></span>
                              Articles
                            </p>
                            <h2>{node.title}</h2>
                          </a>
                        </div>
                        <div className="resources-bottom-box p-3">
                          <p>{node.subtitle.substring(0, 100)}...</p>
                          <a
                            href={`/blog/${year}/${monthString}/${node.slug}/`}
                            className="light-blue"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Read blog{' '}
                            <i className="blue-pointer-right-resources"></i>
                          </a>
                        </div>
                      </div>
                    )
                  }
                  // Pure Webinars
                  // Falling under Webinars & videos
                  else {
                    // Grabbing the date from Contentful Node
                    let tempDate = node.date
                    // Create new Date object from the tempDate
                    let dateToDisplay = new Date(tempDate)
                    // Getting the year from the date - used in the url
                    let year = dateToDisplay.getFullYear()
                    return (
                      <div
                        className="col-sm-12 col-md-6 col-lg-4 mb-5 mt-3"
                        key={uuidv4()}
                      >
                        <div className="bg-light-gray p-3 rounded-top-1 resources-top-box resources-videos-bg-hover">
                          <a
                            href={`/webinars/${year}/${node.slug}/`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <p className="f-14 fw-600 cera-pro">
                              <span className="resources-category-dot bg-coral mr-2"></span>
                              Webinars & Videos
                            </p>
                            <h2>{node.title}</h2>
                          </a>
                        </div>
                        <div className="resources-bottom-box p-3">
                          <p>
                            <a
                              href={`/author/${node.author.name
                                .replace(/\s+/g, '-')
                                .toLowerCase()}/`}
                            >
                              {node.author.name}
                            </a>
                            ,
                            <span className="f-10">
                              {' '}
                              {dateToDisplay.toLocaleDateString(
                                'en-US',
                                dateOptions
                              )}{' '}
                            </span>
                          </p>
                          <a
                            href={`/webinars/${year}/${node.slug}/`}
                            className="light-blue "
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Watch webinar{' '}
                            <i className="blue-pointer-right-resources"></i>
                          </a>
                        </div>
                      </div>
                    )
                  }
                })}
              </div>
            </div>
            <div className="col-sm-12">
              <div className="text-center mt-5">
                <a href="#!">
                  {' '}
                  <span
                    id="handleMore"
                    className="primary-text cursor-pointer"
                    onClick={handleMore}
                  >
                    More &#709;
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default resources
